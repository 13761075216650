<template>
  <div>
    <base-page-title :titleText="clientName" />
    <b-row v-if="!isClientMultiuser" class="no-gutters p-3">
      <b-button @click="openNewProjectModal" class="ml-auto">
        {{ $t("button.createNewProject") }}
      </b-button>
    </b-row>
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <div v-if="isProjectListEmpty">
        <h5 class="text-center mt-5">{{ $t("emptyList") }}</h5>
      </div>
      <div v-else>
        <div v-for="project in clientProjects" :key="project._id">
          <client-project-list-item
            :project="project"
            :branch="branchDetails(project)"
          />
        </div>
        <h4 class="mt-4" v-if="researchOnlyProjects.length !== 0">
          {{ $t(`project.researchOnly`) }}
        </h4>
        <div v-for="project in researchOnlyProjects" :key="project._id">
          <client-project-list-item :project="project" />
        </div>
      </div>
    </b-col>
    <client-project-modal
      v-if="newProjectModalStatus"
      projectType="client"
      :clientId="clientId"
      @closeNewProjectModal="closeNewProjectModal"
    />
  </div>
</template>

<script>
import ClientProjectModal from "../components/Client/ClientProjectModal.vue";
import ClientProjectListItem from "./../components/Client/ClientProjectListItem";

export default {
  components: {
    ClientProjectListItem,
    ClientProjectModal,
  },
  data() {
    return {
      pageTitle: "page.clientProjects.title",
      newProjectModalStatus: false,
      client: null,
    };
  },

  created() {
    const clientList = this.$store.getters["clients/getClientList"];
    this.client = clientList.find(
      (client) => client._id === this.$route.params.id
    );
  },

  computed: {
    clientId() {
      return this.$route.params.id;
    },
    clientName() {
      return this.client.firstName + " " + this.client.lastName;
    },
    clientProjects() {
      return this.$store.getters["clients/getActiveClientProjectList"].filter(
        (project) => project.type !== "researchonly"
      );
    },
    isProjectListEmpty() {
      return (
        this.clientProjects.length === 0 &&
        this.researchOnlyProjects.length === 0
      );
    },
    researchOnlyProjects() {
      return this.$store.getters["clients/getActiveClientProjectList"].filter(
        (project) => project.type === "researchonly"
      );
    },
    isClientMultiuser() {
      return this.client.multiUser;
    },
  },

  methods: {
    openNewProjectModal() {
      this.newProjectModalStatus = true;
    },
    closeNewProjectModal() {
      this.newProjectModalStatus = false;
    },
    branchDetails(project) {
      if (this.isClientMultiuser) {
        return this.client.branches.find((branch) => {
          return project.branchId === branch._id;
        });
      }
    },
  },
};
</script>

<style></style>
