<template>
  <b-card class="mb-2">
    <b-row class="no-gutters align-items-center">
      <b-col>
        <div class="font-weight-bold">{{ project.name }}</div>
        <div v-if="branch">
          {{ $t("branch") + ":" }}
          <span class="font-weight-bold">
            {{ branchName }}
          </span>
        </div>
        <div>
          {{ $t("page.clientProjects.projectStatus") }}
          <span class="font-weight-bold text-uppercase">
            {{ project.status }}
          </span>
        </div>
      </b-col>
      <b-col v-if="project.status === 'closed'" class="col-auto mr-2">
        <b-button size="sm" variant="outline-primary" @click="preview">
          Preview
          <!-- {{ $t("button.details") }} -->
        </b-button>
      </b-col>
      <!-- <b-col v-else class="col-auto mr-2">
        <span style="font-size: 0.8rem" class=" text-red">
          No analysis file
        </span>
      </b-col> -->
      <b-col class="col-auto mr-2">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="viewProjectDetails"
        >
          {{ $t("button.details") }}
        </b-button>
      </b-col>
      <b-col class="col-auto">
        <b-button
          size="sm"
          variant="outline-red"
          @click="openDeleteConfirmationDialog"
        >
          {{ $t("button.delete") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
  },
  computed: {
    isResearchOnly() {
      return this.project.type === "researchonly";
    },
    branchName() {
      return this.branch.name;
    },
  },
  methods: {
    viewProjectDetails() {
      if (this.project.type === "researchonly") {
        this.$router.push({
          name: "ClientNewProject",
          params: {
            projectId: this.project._id,
            clientId: this.project.clientId
          }
        });
      } else {
        this.$router.push({
          name: "ProjectDetails",
          params: {
            projectId: this.project._id,
            clientId: this.project.clientId
          }
        });
      }
    },
    preview() {
      this.$store.dispatch("analysis/RESET");
      this.$router.push({
        name: "ranking",
        params: {
          projectId: this.project._id,
          clientId: this.project.clientId
        }
      });
    },
    openDeleteConfirmationDialog() {
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteProjectTitle",
        messageText: "dialog.confirm.deleteProjectMsg",
        messageParams: { projectName: this.project.name },
        confirmBtnCallback: this.deleteProject,
        type: "DELETE"
      });
    },
    deleteProject() {
      const projectId = this.project._id;
      const clientId = this.project.clientId;

      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("projects/deleteProjectByClientId", { clientId, projectId })
        .then(() => {
          return this.$store.dispatch(
            "clients/retrieveProjectsByClientId",
            clientId
          );
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    }
  }
};
</script>

<style></style>
