<template>
  <b-modal visible centered no-close-on-esc no-close-on-backdrop size="lg">
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase text-red font-weight-bold">
        {{ $t(`project.researchOnly`) }}
      </h5>
    </template>
    <template v-slot:default>
      <div v-if="branchName" class="mb-3">
        <p class="font-weight-bold mb-1">
          {{ $t("branchName") }}
        </p>
        <b-form-input v-model="branchName" disabled></b-form-input>
      </div>
      <p class="font-weight-bold mb-1">
        {{ $t("project.projectName") }}
      </p>
      <b-form-input v-model="newProjectName"></b-form-input>
    </template>
    <template v-slot:modal-footer>
      <b-button
        variant="link"
        class="text-dark text-decoration-none"
        @click="closeNewProjectModal"
        >{{ $t("button.cancel") }}
      </b-button>
      <b-button
        :disabled="newProjectName.length < 1"
        @click="createNewProject"
        >{{ $t("button.proceed") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
export default {
  emits: ["closeNewProjectModal"],
  props: {
    projectType: {
      type: String,
      required: true,
    },
    clientId: {
      type: String,
    },
    branchId: {
      type: String,
    },
    branchName: {
      type: String,
    },
  },
  created() {
    this.generateNewProjectName();
  },
  data() {
    return {
      newProjectName: "",
    };
  },
  methods: {
    createNewProject() {
      if (this.projectType === "client") {
        this.createClientProject();
      } else if (this.projectType === "branch") {
        this.createBranchProject();
      }
    },
    createClientProject() {
      this.$store
        .dispatch("projects/createResearchProject", {
          clientId: this.clientId,
          name: this.newProjectName,
        })
        .then((project) => {
          // console.log(project);
          this.$router.push({
            name: "ClientNewProject",
            params: {
              clientId: this.clientId,
              projectId: project._id,
            },
          });
        });
    },
    createBranchProject() {
      this.$store
        .dispatch("projects/createResearchProject", {
          clientId: this.clientId,
          branchId: this.branchId,
          name: this.newProjectName,
        })
        .then((project) => {
          console.log(project);
          this.$router.push({
            name: "ClientNewProject",
            params: {
              clientId: this.clientId,
              projectId: project._id,
            },
          });
        });
    },
    generateNewProjectName() {
      const researchOnly = this.$t("project.researchOnly").toUpperCase();
      const newProject = this.$t("project.newProject");
      const date = `${dayjs().format("DD/MM/YYYY, HH:mm:ss")}`;
      this.newProjectName = researchOnly + " - " + newProject + " " + date;
    },
    closeNewProjectModal() {
      this.$emit("closeNewProjectModal");
    },
  },
};
</script>

<style></style>
